<template>

  <div class="d-flex flex-row justify-content-center my-3 defender">

    <div class="form-group">
      <label for="defUnits">units</label>
      <input type="number"
        class="form-control"
        id="defUnits"
        size="4"
        v-model="instance.units"
        @change="delta"
        >
        <!--
        v-model="units"
        -->
    </div>

    <div class="form-group mx-4">
      <label for="atkSides">attack die</label>
      <input type="number"
        class="form-control"
        id="atkSides"
        size="4"
        v-model="instance.sides.atk"
        @change="delta"
        >
    </div>

    <div class="form-group">
      <label for="defSides">defend die</label>
      <input type="number"
        class="form-control"
        id="defSides"
        size="4"
        v-model="instance.sides.def"
        @change="delta"
        >
    </div>

  </div>

</template>

<script>

export default {
  props: ['uid','units','sides','deef'],

  computed: {
    instance() { return this.deef
      /*return {
        uid: this.uid,
        units: this.units,
        sides: this.sides,
        //deef: this.deef
      }*/
    }
  },

  data () {
    return {
      nom: '@dice/defender',
      error: null,
      debug: 1,
    }
  },

  components: {},
  created() {},

  mounted() {
    if (this.debug) {
      console.debug('%s.mounted(%s)', this.nom, this.uid)
      //console.debug(JSON.stringify(this.deef))
      //console.debug(this.instance.deef)
    }
    //this.$emit('delta',{ uid: this.uid, units: this.units, sides: this.sides })
  },

  methods: {

    delta() {
      if (this.debug) {
        console.debug('%s.delta(%s)', this.nom, this.uid)
      }
      this.$emit('delta', this.instance)
    }

  }
}

</script>
